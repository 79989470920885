<template>
  <div class="printFile">
    <s-header />
    <n-bar :userType="'teacher'" :activeItemName="'printCenter'" />
    <support />

    <div class="pageContent">
      <div class="in main">
        <van-steps :active="active" active-color="#18a4e0">
          <van-step>选择文档</van-step>
          <van-step>完善信息</van-step>
          <van-step>{{ isPrint }}</van-step>
          <van-step>完成</van-step>
        </van-steps>

        <router-view
          @changeActive="changeActive"
          @changeFile="changeFile"
          @changeIsPrint="changeIsPrint"
          :file="fileInfo"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";

let active = ref(
  localStorage.getItem("active") ? localStorage.getItem("active") : 0
);
let isPrint = ref("打印中");
const changeIsPrint = (val) => {
  isPrint.value = val;
};

const changeActive = (value) => {
  if (value) {
    active.value = value;
  } else {
    active.value++;
  }
};

let fileInfo = ref({
  filePath: "",
  fileName: "",
});
const changeFile = (file) => {
  fileInfo.value.filePath = file.file.url;
  fileInfo.value.fileName = file.file.name;
};
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";
@import "@/common/style/adapting.less";
.main {
  border-radius: 6px;
  padding-bottom: 100px;
}
</style>
